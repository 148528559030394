/* eslint-disable no-undef */
import React from 'react';
import { Title, TextInput, SelectInput, CheckboxInput } from 'components/';
import { connect } from 'react-redux';
import { history } from '/store';
import { withRouter } from 'react-router-dom';
import { StyledStripeSection } from './StripeSection.styled';
import { withTranslation } from 'react-i18next';
import { Paragraph } from 'components/';
import { tt, tlink } from '../../../utils/translationHelper';
import queryString from 'query-string';
import * as yup from 'yup';
import { payBooking, payInfraction, stripeCreatePaymentIntent } from 'services/api';
import { fetchDocumentTypes, fetchFiscalConditions } from '../../../actions/global.actions';
import { BookingStatus } from '../../../utils/constants';
import Storage from 'utils/storage';
import { Typography } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import CheckoutForm from './CheckoutForm';

import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

import {
  getDocumentSample,
  isValidDocument,
  transformDocTypesToSelectOptions,
  transformFiscalConditionsToSelectOptions,
} from '../../../utils/documentHelper';

import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
} from '../../../utils/googleTagsHelper';
import BasePaymentSection from '../basePaymentComponent/BasePaymentSection';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';

class StripeSection extends BasePaymentSection {
  constructor(props) {
    const {
      t,
      withBillingInformation,
      settings: {
        configurations: { fiscalConditionValidation },
      },
    } = props;
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
        qsamount: 0,
        infractionId: '0',
        customerId: '0',
        act: '',
        branchOfficeId: 0,
        promotionId: '',
        noPendingPayTransactions: true,
        showBillingInfo: withBillingInformation !== undefined ? withBillingInformation : false,
        fiscalConditionId: null,
        currencyCode: '',
        exchangeRate: 1,
        installment: 1,
      },
      formErrors: {
        documentType: '',
        document: '',
        firstName: '',
        lastName: '',
        fiscalConditionId: null,
        billingDocumentType: '',
        documentId: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        cellPhone: '',
        emailAddress: '',
      },
      formLoading: false,
      disableBillingFields: false,
      fiscalConditions: [],
      stripePromise: null,
      clientSecret: null,
    };

    yup.addMethod(yup.string, 'isValidDocument', function(documentId) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongDocumentFormat'),
        function(documentId) {
          const { tenantDocumentTypes, billingDocumentType } = this.parent;
          return isValidDocument(tenantDocumentTypes, billingDocumentType, documentId);
        },
      );
    });

    yup.addMethod(yup.string, 'hasValidDocumentType', function(fiscalCondition) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongFiscalCondition'),
        function(fiscalCondition) {
          const taxPayerValidation = (fiscalConditionValidation || [])?.find(
            i => `${i.id}` === fiscalCondition,
          );

          if (!taxPayerValidation?.validDocumentIds) return true;
          else {
            const validIds = taxPayerValidation?.validDocumentIds;
            if (validIds.includes(this.parent.billingDocumentType)) return true;
            return false;
          }
        },
      );
    });

    this.paymentInfoFormSchema = yup.object().shape({
      firstName: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(
          t('inputIsRequired', {
            field: t('billingInformation.fields.firstName'),
          }),
        ),
      }),
      lastName: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(
          t('inputIsRequired', {
            field: t('billingInformation.fields.lastName'),
          }),
        ),
      }),
      fiscalConditionId: yup
        .string()
        .nullable()
        .when('showBillingInfo', {
          is: true,
          then: yup
            .string()
            .hasValidDocumentType()
            .required(
              t('inputIsRequired', {
                field: t('billingInformation.fields.fiscalCondition'),
              }),
            ),
        }),
      billingDocumentType: yup.number().when('showBillingInfo', {
        is: true,
        then: yup.number().required(t('inputIsRequired', { field: t('tipodocumento') })),
      }),
      documentId: yup.string().when('showBillingInfo', {
        is: true,
        then: yup
          .string()
          .isValidDocument()
          .required(
            t('inputIsRequired', {
              field: t('billingInformation.fields.document'),
            }),
          ),
      }),
      address: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('addressName') })),
      }),
      addressNumber: yup
        .string()
        .nullable(true)
        .when('showBillingInfo', {
          is: true,
          then: yup
            .string()
            .typeError(t('invalidNumber'))
            .required(t('inputIsRequired', { field: t('addressNumber') })),
        }),
      zipCode: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('zipCode') })),
      }),
      cellPhone: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('phone') })),
      }),
      emailAddress: yup
        .string()
        .email(t('validEmail'))
        .when('showBillingInfo', {
          is: true,
          then: yup.string().required(t('inputIsRequired', { field: t('mail') })),
        }),
    });

    this.formRef = React.createRef(null);
  }

  //#region Delete

  handleSubmit = e => {
    e.preventDefault();
    const paymentInfo = this.state.paymentInfo;
    this.setState({ formLoading: true });

    this.paymentInfoFormSchema
      .validate(paymentInfo, { abortEarly: false })
      .then(valid => {
        this.executePayment();
      })
      .catch(err => {
        this.setState({ formLoading: false });
        const formErrors = err.inner.reduce((prevErrors, currentError) => {
          return { ...prevErrors, [currentError.path]: currentError.message };
        }, {});
        window.scrollTo(0, this.formRef.current.offsetTop);
        this.setState(prevState => ({ ...prevState, formErrors }));
      });
  };

  executePayment = async () => {
    const {
      t,
      i18n,
      bookings,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
    } = this.props;

    let paymentParams = this.state.paymentInfo;
    paymentParams.gatewayId = 'STRIPE';
    paymentParams.orderID = this.state.paymentInfo.bookingId;

    try {
      // TODO: Encontrar una solucion mas elegante al paso de info entre el iframe y react.
      var paymentParamsCopy = {
        ...paymentParams,
        isPayingBooking: this.isPayingBooking(),
      };
      Storage.setElement('paymentParams', JSON.stringify(paymentParamsCopy), false);

      const res = await this.makePayment(paymentParams);
    } catch (error) {
      let errorMessage = t('stripeGenericError');
      let codeError = '';
      let messageError = '';

      if (error && error.response && error.response.data && error.response.data.code) {
        messageError = error.response.data.message;
        codeError = error.response.data.code;
      }

      switch (codeError) {
        case 402:
          errorMessage = t('stripeAddPaymentFailed', {
            errorMessage: messageError,
          });
          break;
        case 403:
          errorMessage = t('stripeAddPaymentRentlyFailed', {
            transactionId: paymentParams.orderID,
          });
          break;
        case 404:
          errorMessage = t('stripeAddPaymentRequiresAction', {
            transactionId: paymentParams.orderID,
          });
          break;
        default:
          break;
      }

      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        { error: errorMessage },
      );
    }
  };

  makePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  //#endregion

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  sanitizeName(name) {
    if (!name) {
      return name;
    }
    return name.replace(/[^a-zA-Z0-9]/g, '');
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      noPendingPayTransactions,
      fetchDocumentTypes,
      fetchFiscalConditions,
      listDocumentTypes,
      listTaxPayerTypes,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;

    let tenantDocumentTypes = listDocumentTypes || [];

    if (this.state.paymentInfo.showBillingInfo && tenantDocumentTypes.length === 0)
      tenantDocumentTypes = (await fetchDocumentTypes('', i18n.language)).payload;

    const tenantFiscalConditions =
      listTaxPayerTypes || (await fetchFiscalConditions(i18n.language))?.payload;

    this.setState(prevState => ({
      ...prevState,
      fiscalConditions: tenantFiscalConditions,
    }));

    const parseQs = queryString.parse(location.search);

    const customer = this.getCustomerInfo();

    if (this.isPayingBooking() && bookings.confirmation != null) {
      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (defaultCurrency && bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process(
          { number: 3, option: 'Online Payment' },
          bookings.confirmation,
          settings,
        );
        gtag_checkout_option({ number: 3, option: 'Stripe' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: bookings.confirmation.id,
          email: bookings.confirmation.customer.emailAddress,
          branchOfficeId: bookings.confirmation.deliveryPlace.branchOfficeId,
          promotionId: parseQs.promotionid || null,
          noPendingPayTransactions:
            noPendingPayTransactions !== undefined ? noPendingPayTransactions : true,
          installment: '1',
          firstName: bookings.confirmation.customer.firstName,
          lastName: bookings.confirmation.customer.lastName,
          fiscalConditionId: null,
          billingDocumentType: 0,
          documentId: '',
          address: '',
          addressNumber: null,
          zipCode: '',
          cellPhone: '',
          emailAddress: '',
          tenantDocumentTypes,
          billingInfoCheckbox: false,
          disableBillingFields: false,
          currencyCode: bookings.confirmation.currency,
          exchangeRate: 1,
          customerId: bookings.confirmation.customer.id,
        },
        sampleDocFormat: getDocumentSample(tenantDocumentTypes, customer.documentType),
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          branchOfficeId: infraction.booking?.deliveryPlace?.branchOfficeId || null,
          firstName: '',
          lastName: '',
          fiscalConditionId: null,
          billingDocumentType: 0,
          documentId: '',
          address: '',
          addressNumber: null,
          zipCode: '',
          cellPhone: '',
          emailAddress: '',
          tenantDocumentTypes,
          billingInfoCheckbox: false,
          disableBillingFields: false,
          currencyCode: defaultCurrency.isoCode, //Infractions are paid in RMS System Currency.
          exchangeRate: 1,
        },
      }));
    }
    this.loadStripePromise();
  }

  loadStripePromise = async () => {
    const { credentials } = this.props;

    if (credentials) {
      let stripePromise = loadStripe(credentials.publicApiKey);
      this.setState({ ...this.state, stripePromise });
      this.createPaymentIntent();
    }
  };

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= Math.abs(qsamount)) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }
      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction())
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0')}`;
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getCustomerInfo = () => {
    if (this.isPayingBooking()) return this.props.bookings.confirmation.customer;
    if (this.isPayingInfraction() && this.props.infractions.current) {
      return this.props.infractions.current.booking
        ? this.props.infractions.current.booking.customer
        : {};
    }
  };

  //#region BillingInformation

  handleCheckboxUseContactInfoChange = checked => {
    const customer = this.getCustomerInfo();

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        billingInfoCheckbox: checked,
        disableBillingFields: checked,
        firstName: checked ? customer.firstName : prevState.paymentInfo.firstName,
        lastName: checked ? customer.lastName : prevState.paymentInfo.lastName,
        fiscalConditionId: checked
          ? customer.fiscalConditionId
          : prevState.paymentInfo.fiscalConditionId,
        billingDocumentType: checked
          ? customer.documentTypeId || 0
          : prevState.paymentInfo.billingDocumentType,
        documentId: checked ? customer.documentId : prevState.paymentInfo.documentId,
        address: checked ? customer.address : prevState.paymentInfo.address,
        addressNumber: checked
          ? customer.addressNumber || null
          : prevState.paymentInfo.addressNumber,
        addressDepartment: checked ? customer.addressDepartment || '' : '',
        zipCode: checked ? customer.zipCode : prevState.paymentInfo.zipCode,
        cellPhone: checked ? customer.cellPhone : prevState.paymentInfo.cellPhone,
        emailAddress: checked ? customer.emailAddress : prevState.paymentInfo.emailAddress,
        customerId: checked ? customer.id : prevState.paymentInfo.customerid,
      },
      formErrors: {
        firstName: '',
        lastName: '',
        fiscalConditionId: null,
        billingDocumentType: '',
        documentId: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        cellPhone: '',
        emailAddress: '',
        customerId: '0',
      },
    }));
  };

  handleFirstName = firstName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        firstName,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, firstName: '' },
    }));
  };

  handleLastName = lastName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        lastName,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, lastName: '' },
    }));
  };

  handleEmailAddress = emailAddress => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        emailAddress,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, emailAddress: '' },
    }));
  };

  handleAddress = address => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        address,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, address: '' },
    }));
  };

  handleAddressNumber = addressNumber => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        addressNumber,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, addressNumber: '' },
    }));
  };

  handleAddressDepartment = addressDepartment => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        addressDepartment,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, addressDepartment: '' },
    }));
  };

  handleZipCode = zipCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        zipCode,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, zipCode: '' },
    }));
  };

  handleCellphone = cellPhone => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        cellPhone,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, cellPhone: '' },
    }));
  };

  handleFiscalCondition = fiscalConditionId => {
    const {
      settings: { configurations },
    } = this.props;
    const validDocumentIds = (configurations?.fiscalConditionValidation || []).find(
      i => `${i.id}` === fiscalConditionId,
    )?.validDocumentIds;
    let billingDocumentType = this.state.paymentInfo.billingDocumentType;

    if (
      validDocumentIds &&
      (!billingDocumentType || !validDocumentIds?.includes(billingDocumentType))
    ) {
      billingDocumentType = validDocumentIds[0];
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        fiscalConditionId,
        billingDocumentType,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, fiscalConditionId: null },
    }));
  };

  handleBillingDocumentType = billingDocumentType => {
    const { listDocumentTypes } = this.props;

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        billingDocumentType,
        billingInfoCheckbox: false,
      },
      formErrors: {
        ...prevState.formErrors,
        billingDocumentType: '',
        fiscalConditionId: null,
      },
      sampleDocFormat: getDocumentSample(listDocumentTypes, billingDocumentType),
    }));
  };

  handleDocumentId = documentId => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        documentId,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, documentId: '' },
    }));
  };

  renderBillingInfoSection = () => {
    const { t, billingSubtitle, billingConditions } = this.props;

    return (
      <>
        <div className="billing-form pb-1 pt-3 payment-code">
          <hr />
          <div className="col-md-12 p-0">
            <p className="mb-2">
              <strong>{t('billingInformation.title')}</strong>
            </p>
            {billingSubtitle && <p className="label mb-2">{t(billingSubtitle)}</p>}
            <CheckboxInput
              onChange={this.handleCheckboxUseContactInfoChange}
              checked={this.state.paymentInfo.billingInfoCheckbox}
              text={<p className="mb-1">{t('billingInformation.equalToContactInfo')}</p>}
            />
            {this.renderBillingFields()}
          </div>
          {billingConditions && <Paragraph text={billingConditions} />}
        </div>
      </>
    );
  };

  renderBillingFields = () => {
    const { t } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('billingInformation.fields.firstName')}
                value={this.state.paymentInfo.firstName}
                error={this.state.formErrors.firstName}
                onChange={this.handleFirstName}
                options={{ autoComplete: 'billingInfo.firstName' }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('billingInformation.fields.lastName')}
                value={this.state.paymentInfo.lastName}
                error={this.state.formErrors.lastName}
                onChange={this.handleLastName}
                options={{ autoComplete: 'billingInfo.lastName' }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('mail')}
                value={this.state.paymentInfo.emailAddress}
                error={this.state.formErrors.emailAddress}
                onChange={this.handleEmailAddress}
                options={{ autoComplete: 'billingInfo.emailAddress' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressName')}
                value={this.state.paymentInfo.address}
                error={this.state.formErrors.address}
                onChange={this.handleAddress}
                options={{ autoComplete: 'address-line1' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressNumber')}
                value={this.state.paymentInfo.addressNumber}
                error={this.state.formErrors.addressNumber}
                onChange={this.handleAddressNumber}
                options={{ autoComplete: 'addressNumber' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressDepartment')}
                value={this.state.paymentInfo.addressDepartment}
                error={this.state.formErrors.addressDepartment}
                onChange={this.handleAddressDepartment}
                options={{ autoComplete: 'addressDepartment' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('zipCode')}
                value={this.state.paymentInfo.zipCode}
                error={this.state.formErrors.zipCode}
                onChange={this.handleZipCode}
                options={{ autoComplete: 'zipCode' }}
              />
            </div>
          </div>
          <div className={!this.state.paymentInfo.showBillingInfo ? 'col-md-4' : 'col-md-3'}>
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('phone')}
                value={this.state.paymentInfo.cellPhone}
                error={this.state.formErrors.cellPhone}
                onChange={this.handleCellphone}
                options={{ autoComplete: 'tel' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <SelectInput
                inputClass="form-control"
                onChange={this.handleFiscalCondition}
                selected={this.state.paymentInfo.fiscalConditionId}
                options={transformFiscalConditionsToSelectOptions(this.state.fiscalConditions, t)}
                error={this.state.formErrors.fiscalConditionId}
                defaultOption={t('billingInformation.fields.fiscalCondition')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <SelectInput
                inputClass="form-control"
                onChange={this.handleBillingDocumentType}
                options={transformDocTypesToSelectOptions(this.props.listDocumentTypes) || []}
                selected={this.state.paymentInfo.billingDocumentType}
                error={this.state.formErrors.billingDocumentType}
                defaultOption={t('tipodocumento')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <TextInput
              inputClass="form-control"
              placeholder={t('billingInformation.fields.document')}
              value={this.state.paymentInfo.documentId}
              error={this.state.formErrors.documentId}
              onChange={this.handleDocumentId}
              options={{ autoComplete: 'document' }}
              legend={
                this.state.sampleDocFormat
                  ? t('billingInformation.fields.formatExpected', {
                      format_expected: this.state.sampleDocFormat,
                    })
                  : null
              }
            />
          </div>
        </div>
      </>
    );
  };

  //#endregion

  createPaymentIntent = () => {
    const paymentInfo = this.state.paymentInfo;

    stripeCreatePaymentIntent(
      paymentInfo.branchOfficeId,
      paymentInfo.price,
      paymentInfo.currencyCode,
      paymentInfo.bookingId,
      paymentInfo.infractionId,
      paymentInfo.customerId,
      this.sanitizeName(paymentInfo.firstName) + ' ' + this.sanitizeName(paymentInfo.lastName),
      paymentInfo.email,
    )
      .then(res => res)
      .then(data => this.setClientSecret(data));

    this.setSuccessStorageElements();
  };

  setClientSecret = clientSecret => {
    this.setState({ ...this.state, clientSecret });
  };

  setSuccessStorageElements = () => {
    const paymentInfo = this.state.paymentInfo;

    var params = {
      bookingId: paymentInfo.bookingId,
      infractionId: paymentInfo.infractionId,
      price: paymentInfo.price,
      currencyCode: paymentInfo.currencyCode,
      exchangeRate: paymentInfo.exchangeRate,
    };
    Storage.setElement('stripeTransactionParams', JSON.stringify(params), false);
  };

  render() {
    const { t, title, subtitle, currentCurrency, defaultCurrency, webCurrency } = this.props;

    const { stripePromise, clientSecret } = this.state;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let totalToPay = this.showTotalToPay(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };

    return (
      <StyledStripeSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPay()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block ml-2 pt-3">
            <div className="stripe-form_wrapper">
              {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
          <div className="row adicionales-block mb-5 ml-2 pt-3">
            <div className="row">
              <div className="logo-container">
                <img
                  width="151"
                  height="52"
                  className="stripe-logo"
                  src="/images/payments/powered_by_stripe.svg"
                  alt="Stripe"
                />
              </div>
            </div>

            {/* <div className="col-md-10 form-pago">
              <form id="stripe-form" onSubmit={this.handleSubmit} ref={this.formRef}>
                {this.state.paymentInfo.showBillingInfo && this.renderBillingInfoSection()}
              </form>
            </div> */}
          </div>
        </div>
      </StyledStripeSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, siteConfiguration, global }) => ({
  bookings: bookings,
  infractions: infractions,
  settings: siteConfiguration.settings,
  listDocumentTypes: global.listDocumentTypes,
  listTaxPayerTypes: global.listTaxPayerTypes,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps, { fetchDocumentTypes, fetchFiscalConditions })(
  withRouter(withTranslation()(StripeSection)),
);
