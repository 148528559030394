/* eslint-disable no-undef */
import React from 'react';
import {
  Title,
  NumberDisplay,
  TextInput,
  SelectInput,
  Btn,
  ScriptTagComponent,
  CheckboxInput,
} from 'components/';
import { connect } from 'react-redux';
import { history } from '/store';
import { withRouter } from 'react-router-dom';
import { StyledMercadoPagoSection } from './MercadoPagoSection.styled';
import { withTranslation } from 'react-i18next';
import { Paragraph } from 'components/';
import { tt, tlink } from '../../../utils/translationHelper';
import queryString from 'query-string';
import * as yup from 'yup';
import { payBooking, payInfraction } from 'services/api';
import { setBookingInstallment } from 'actions/bookings.actions';
import { setInfractionInstallment } from 'actions/infractions.actions';
import { fetchDocumentTypes, fetchFiscalConditions } from '../../../actions/global.actions';
import { BookingStatus } from '../../../utils/constants';
import { Typography } from '@material-ui/core';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import {
  cleanRUT,
  getDocumentSample,
  isValidDocument,
  transformDocTypesToSelectOptions,
  transformFiscalConditionsToSelectOptions,
} from '../../../utils/documentHelper';

import {
  is_gtag_enabled,
  gtag_checkout_process,
  gtag_checkout_option,
  gtag_purchase,
} from '../../../utils/googleTagsHelper';
import { getDocumentMask, getDocumentTypeName, handleGetTotalToPay } from 'utils/dataHelper';
import { getPriceWithExchangeRate, applyCurrencyExchangeRate } from 'utils/priceHelper';
import PartialPayWarning from '../partialPayWarning';

const RENTLY_PAY_NOTIFICACION_FAILED_CODE = 10101;

class MercadoPagoSection extends React.Component {
  constructor(props) {
    const {
      t,
      withBillingInformation,
      settings: {
        configurations: { fiscalConditionValidation },
      },
    } = props;
    super(props);
    this.state = {
      paymentInfo: {
        price: 0,
        bookingId: '0',
        qsamount: 0,
        showDoc: false,
        showInstallments: true,
        infractionId: '0',
        customerId: '0',
        act: '',
        branchOfficeName: '',
        branchOfficeIATA: '',
        branchOfficeId: 0,
        promotionId: '',
        noPendingPayTransactions: true,
        showBillingInfo: withBillingInformation !== undefined ? withBillingInformation : false,
        fiscalConditionId: null,
        currencyCode: '',
        exchangeRate: 1,
        bin: '',
      },
      formErrors: {
        installment: '',
        documentType: '',
        bank: '',
        cardNumber: '',
        bin: '',
        document: '',
        cardholderName: '',
        year: '',
        month: '',
        secCode: '',
        firstName: '',
        lastName: '',
        fiscalConditionId: null,
        billingDocumentType: '',
        documentId: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        cellPhone: '',
        emailAddress: '',
      },
      cardIcon: 'far fa-credit-card',
      formLoading: false,
      disableBillingFields: false,
      fiscalConditions: [],
      docInputFocus: false,
      billingDocInputFocus: false,
      sampleDocFormat: '',
      sampleBillingDocFormat: '',
      mp: null,
      currencySymbol: '',
    };

    yup.addMethod(yup.string, 'isValidDocumentId', function(documentId) {
      return this.test(
        'test-document-id-format',
        t('billingInformation.errors.wrongDocumentFormat'),
        function(documentId) {
          const { tenantDocumentTypes, billingDocumentType } = this.parent;
          return isValidDocument(tenantDocumentTypes, billingDocumentType, documentId);
        },
      );
    });

    yup.addMethod(yup.string, 'isValidDocument', function(document) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongDocumentFormat'),
        function(document) {
          const { documentType, documents } = this.parent;
          return isValidDocument(documents, documentType, document);
        },
      );
    });

    yup.addMethod(yup.string, 'hasValidDocumentType', function(fiscalCondition) {
      return this.test(
        'test-document-format',
        t('billingInformation.errors.wrongFiscalCondition'),
        function(fiscalCondition) {
          const taxPayerValidation = (fiscalConditionValidation || [])?.find(
            i => `${i.id}` === fiscalCondition,
          );

          if (!taxPayerValidation?.validDocumentIds) return true;
          else {
            const validIds = taxPayerValidation?.validDocumentIds;
            if (validIds.includes(this.parent.billingDocumentType)) return true;
            return false;
          }
        },
      );
    });

    this.paymentInfoFormSchema = yup.object().shape({
      showDoc: yup.boolean(),
      showInstallments: yup.boolean(),
      installment: yup.string().when('showInstallments', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('cuotas') })),
      }),
      documentType: yup.string().when('showDoc', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('tipodocumento') })),
      }),
      // cardNumber: yup
      //   .number()
      //   .required(t('inputIsRequired', { field: t('numeroTarjeta') })),
      document: yup.string().when('showDoc', {
        is: true,
        then: yup
          .string()
          .isValidDocument()
          .required(t('inputIsRequired', { field: t('documento') })),
      }),
      cardholderName: yup.string().required(t('inputIsRequired', { field: t('titular') })),
      // year: yup.number().required(t('inputIsRequired', { field: t('año') })),
      // month: yup.number().required(t('inputIsRequired', { field: t('mes') })),
      // securityCode: yup
      //   .number()
      //   .required(t('inputIsRequired', { field: t('codigo') })),
      firstName: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(
          t('inputIsRequired', {
            field: t('billingInformation.fields.firstName'),
          }),
        ),
      }),
      lastName: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(
          t('inputIsRequired', {
            field: t('billingInformation.fields.lastName'),
          }),
        ),
      }),
      fiscalConditionId: yup
        .string()
        .nullable()
        .when('showBillingInfo', {
          is: true,
          then: yup
            .string()
            .hasValidDocumentType()
            .required(
              t('inputIsRequired', {
                field: t('billingInformation.fields.fiscalCondition'),
              }),
            ),
        }),
      billingDocumentType: yup.number().when('showBillingInfo', {
        is: true,
        then: yup.number().required(t('inputIsRequired', { field: t('tipodocumento') })),
      }),
      documentId: yup.string().when('showBillingInfo', {
        is: true,
        then: yup
          .string()
          .isValidDocumentId()
          .required(
            t('inputIsRequired', {
              field: t('billingInformation.fields.document'),
            }),
          ),
      }),
      address: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('addressName') })),
      }),
      addressNumber: yup
        .string()
        .nullable(true)
        .when('showBillingInfo', {
          is: true,
          then: yup
            .string()
            .typeError(t('invalidNumber'))
            .required(t('inputIsRequired', { field: t('addressNumber') })),
        }),
      zipCode: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('zipCode') })),
      }),
      cellPhone: yup.string().when('showBillingInfo', {
        is: true,
        then: yup.string().required(t('inputIsRequired', { field: t('phone') })),
      }),
      emailAddress: yup
        .string()
        .email(t('validEmail'))
        .when('showBillingInfo', {
          is: true,
          then: yup.string().required(t('inputIsRequired', { field: t('mail') })),
        }),
    });
    this.formRef = React.createRef(null);
    const year = new Date().getFullYear();
    this.years = Array.from(new Array(15), (val, index) => index + year);
    this.months = Array.from(new Array(12), (val, index) => index + 1);
  }

  componentWillMount() {
    const { location } = this.props;
    const { paymentInfo } = this.state;
    const parseQs = queryString.parse(location.search);

    if (parseQs.amount) {
      try {
        let partial = parseFloat(parseQs.amount);
        if (partial != 0) {
          this.setState({ paymentInfo: { ...paymentInfo, qsamount: partial } });
        }
      } catch {}
    }
  }

  async componentDidMount() {
    const {
      location,
      bookings,
      infractions,
      noPendingPayTransactions,
      workWithInstallments,
      fetchDocumentTypes,
      fetchFiscalConditions,
      listDocumentTypes,
      listTaxPayerTypes,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      defaultCurrency,
    } = this.props;
    let tenantDocumentTypes = listDocumentTypes || [];

    if (this.state.paymentInfo.showBillingInfo && tenantDocumentTypes.length === 0)
      tenantDocumentTypes = (await fetchDocumentTypes('', i18n.language)).payload;

    const tenantFiscalConditions =
      listTaxPayerTypes || (await fetchFiscalConditions(i18n.language))?.payload;

    this.setState(prevState => ({
      ...prevState,
      fiscalConditions: tenantFiscalConditions,
    }));

    const parseQs = queryString.parse(location.search);
    const mustShowInstallments = workWithInstallments !== undefined ? workWithInstallments : true;

    const customer = this.getCustomerInfo();

    if (this.isPayingBooking()) {
      const booking = bookings.confirmation;

      // If the currency is not the system currency and there's an exchange rate
      // use that
      if (bookings.confirmation.currency !== defaultCurrency.isoCode) {
        bookings.confirmation.balance =
          bookings.confirmation.balance * bookings.confirmation.exchangeRate;
        bookings.confirmation.currency = defaultCurrency.isoCode;

        if (bookings.confirmation.exchangeRate !== 1) {
          const exchangeRate = 1 / bookings.confirmation.exchangeRate;
          this.setExchangeRate(exchangeRate);
        }
      }

      if (is_gtag_enabled(googleTagManager)) {
        gtag_checkout_process({ number: 3, option: 'Online Payment' }, booking, settings);
        gtag_checkout_option({ number: 3, option: 'Mercado Pago' });
      }

      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getTotalToPay(),
          bookingId: booking.id,
          email: booking.customer.emailAddress,
          branchOfficeName: booking.deliveryPlace.branchOfficeName,
          branchOfficeIATA: booking.deliveryPlace.branchOfficeIATACode,
          branchOfficeId: booking.deliveryPlace.branchOfficeId,
          promotionId: parseQs.promotionid || null,
          noPendingPayTransactions:
            noPendingPayTransactions !== undefined ? noPendingPayTransactions : true,
          showInstallments: mustShowInstallments,
          installment: '1',
          firstName: '',
          lastName: '',
          fiscalConditionId: null,
          billingDocumentType: 0,
          documentId: '',
          document: '',
          address: '',
          addressNumber: null,
          zipCode: '',
          cellPhone: '',
          emailAddress: '',
          tenantDocumentTypes,
          billingInfoCheckbox: false,
          disableBillingFields: false,
          currencyCode: booking.currency,
          //exchangeRate: 1,
        },
        sampleDocFormat: getDocumentSample(tenantDocumentTypes, customer.documentType),
      }));
    }

    if (this.isPayingInfraction() && parseQs.customerid) {
      const infraction = infractions.current;
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          price: this.getInfractionTotalToPay(),
          infractionId: infraction.id,
          act: infraction.act,
          customerId: parseQs.customerid,
          email: infraction.booking?.customer?.emailAddress || '',
          branchOfficeName: infraction.booking?.deliveryPlace?.branchOfficeName || null,
          branchOfficeIATA: infraction.booking?.deliveryPlace?.branchOfficeIATACode || null,
          branchOfficeId: infraction.booking?.deliveryPlace?.branchOfficeId || null,
          firstName: '',
          lastName: '',
          fiscalConditionId: null,
          billingDocumentType: 0,
          documentId: '',
          address: '',
          addressNumber: null,
          zipCode: '',
          cellPhone: '',
          emailAddress: '',
          tenantDocumentTypes,
          billingInfoCheckbox: false,
          disableBillingFields: false,
          currencyCode: defaultCurrency.isoCode,
          exchangeRate: 1,
        },
      }));
    }

    //Mercado Pago always use default currency.
    this.setCurrencySymbol(defaultCurrency.symbol);
  }

  setCurrencySymbol = currencySymbol => {
    this.setState(prevState => ({
      ...prevState,
      currencySymbol: currencySymbol,
    }));
  };

  getTotalToPay() {
    const {
      bookings: { confirmation },
      settings: { paymentConfiguration },
    } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    let totalToPay = this.isPayingInfraction()
      ? this.getInfractionTotalToPay()
      : handleGetTotalToPay(confirmation.customerBalance, paymentConfiguration, qsamount);
    if (paymentConfiguration?.percentageToPay) {
      const numberOfDecimals = this.getCashBoxConfigurationNumberOfDecimals();
      if (numberOfDecimals == 0) return Math.round(totalToPay);
      else if (numberOfDecimals == 1) return Math.round(totalToPay * 10) / 10;
      else if (numberOfDecimals > 1) return Math.round(totalToPay * 100) / 100;
    }

    return totalToPay;
  }

  getInfractionTotalToPay() {
    const { infractions } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;

    const infraction = infractions.current;
    if (qsamount && Math.abs(infraction.amount) >= Math.abs(qsamount)) {
      return Math.abs(qsamount);
    } else {
      return infraction.amount;
    }
  }

  getCashBoxConfigurationNumberOfDecimals() {
    const { generalSettings } = this.props;
    return generalSettings && generalSettings.cashBoxConfiguration
      ? generalSettings.cashBoxConfiguration.numberOfDecimals
      : 0;
  }

  getPublicToken() {
    const { publicToken, credentials } = this.props;
    if (credentials && credentials.publicToken) return credentials.publicToken;

    return publicToken;
  }

  onLoaded = () => {
    const publicToken = this.getPublicToken();
    const mp = new MercadoPago(publicToken);
    this.setState({ ...this.state, mp });

    mp.fields
      .create('expirationDate', {
        placeholder: 'MM/YY',
      })
      .mount('expirationDate');

    mp.fields
      .create('securityCode', {
        placeholder: this.props.t('codigo'),
      })
      .mount('securityCode');

    const cardNumberElement = mp.fields
      .create('cardNumber', {
        placeholder: this.props.t('numeroTarjeta'),
      })
      .mount('cardNumber');

    cardNumberElement.on('binChange', this.getPaymentMethods);
  };

  identificationHandler = (status, response) => {
    const newDocuments = [];
    if (status === 200) {
      for (let i = 0; i < response.length; i++) {
        newDocuments.push({ value: response[i].id, text: response[i].name });
      }
      this.setState(prevState => ({
        ...prevState,
        paymentInfo: {
          ...prevState.paymentInfo,
          showDoc: true,
          documents: newDocuments,
        },
      }));
    }
  };

  documentSelection = documentType => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, documentType },
      sampleDocFormat: getDocumentSample(this.state.paymentInfo.documents, documentType),
    }));
  };

  bankSelection = async bank => {
    const { paymentInfo, mp } = this.state;

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, bank },
    }));

    if (paymentInfo.showInstallments) {
      const amount = `${this.getTotalToPay()}`;

      let paymentMethodId =
        paymentInfo.paymentMethodID == 'debvisa' || paymentInfo.paymentMethodID == 'debmaster'
          ? 'debit_card'
          : 'credit_card';

      const installments = await mp.getInstallments({
        amount: amount,
        bin: paymentInfo.bin,
        paymentTypeId: paymentMethodId,
      });

      this.installmentHandler(installments);
    }
  };

  installmentsSelection = installment => {
    if (installment === '1' || installment === 'Seleccionar') {
      this.storeInstallment(null);
    } else {
      const installmentObject = this.state.originalInstallments.find(
        inst => inst.installments === Number(installment),
      );
      if (installmentObject) this.storeInstallment(installmentObject);
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, installment },
    }));
  };

  storeInstallment = installment => {
    const { setBookingInstallment, setInfractionInstallment } = this.props;

    if (this.isPayingBooking()) return setBookingInstallment(installment);
    if (this.isPayingInfraction()) return setInfractionInstallment(installment);
  };

  handleCardholderName = cardholderName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, cardholderName },
      formErrors: { ...prevState.formErrors, cardholderName: null },
    }));
  };

  handleDocument = id => {
    let cleanId = id;

    if (this.state.paymentInfo.documentType === 'RUT') {
      cleanId = cleanRUT(id);
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: { ...prevState.paymentInfo, document: cleanId },
      formErrors: { ...prevState.formErrors, document: null },
    }));
  };

  handleFirstName = firstName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        firstName,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, firstName: '' },
    }));
  };

  handleLastName = lastName => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        lastName,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, lastName: '' },
    }));
  };

  handleEmailAddress = emailAddress => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        emailAddress,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, emailAddress: '' },
    }));
  };

  handleAddress = address => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        address,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, address: '' },
    }));
  };

  handleAddressNumber = addressNumber => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        addressNumber,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, addressNumber: '' },
    }));
  };

  handleAddressDepartment = addressDepartment => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        addressDepartment,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, addressDepartment: '' },
    }));
  };

  handleZipCode = zipCode => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        zipCode,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, zipCode: '' },
    }));
  };

  handleCellphone = cellPhone => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        cellPhone,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, cellPhone: '' },
    }));
  };

  handleFiscalCondition = fiscalConditionId => {
    const {
      settings: { configurations },
    } = this.props;
    const validDocumentIds = (configurations?.fiscalConditionValidation || []).find(
      i => `${i.id}` === fiscalConditionId,
    )?.validDocumentIds;
    let billingDocumentType = this.state.paymentInfo.billingDocumentType;

    if (
      validDocumentIds &&
      (!billingDocumentType || !validDocumentIds?.includes(billingDocumentType))
    ) {
      billingDocumentType = validDocumentIds[0];
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        fiscalConditionId,
        billingDocumentType,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, fiscalConditionId: null },
    }));
  };

  handleBillingDocumentType = billingDocumentType => {
    const { listDocumentTypes } = this.props;

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        billingDocumentType,
        billingInfoCheckbox: false,
      },
      formErrors: {
        ...prevState.formErrors,
        billingDocumentType: '',
        fiscalConditionId: null,
      },
      sampleBillingDocFormat: getDocumentSample(listDocumentTypes, billingDocumentType),
    }));
  };

  handleDocumentId = id => {
    let cleanId = id;
    const typeName = getDocumentTypeName(
      this.props.listDocumentTypes,
      this.state.paymentInfo.billingDocumentType,
    );

    if (typeName === 'RUT') {
      cleanId = cleanRUT(id);
    }

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        documentId: cleanId,
        billingInfoCheckbox: false,
      },
      formErrors: { ...prevState.formErrors, documentId: '' },
    }));
  };

  installmentHandler = response => {
    let installments = [];

    for (let i = 0; i < response[0].payer_costs.length; i++) {
      installments.push({
        text: response[0].payer_costs[i].recommended_message,
        value: response[0].payer_costs[i].installments,
      });
    }
    this.setState({
      installments: installments,
      originalInstallments: response[0].payer_costs,
    });
  };

  issuersHandler = response => {
    const banks = [];
    for (let i = 0; i < response.length; i++) {
      banks.push({ value: response[i].id, text: response[i].name });
    }
    this.setState({ banks: banks });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { mp } = this.state;

    this.setState({ formLoading: true });
    const paymentInfo = this.state.paymentInfo;

    this.paymentInfoFormSchema
      .validate(paymentInfo, { abortEarly: false })
      .then(async valid => {
        if (valid) {
          const token = await mp.fields.createCardToken({
            cardholderName: paymentInfo.cardholderName,
            identificationType: paymentInfo.documentType,
            identificationNumber: paymentInfo.document,
          });
          if (token.status == 'active') {
            this.tokenHandler(token);
          } else {
            throw 'token is not active';
          }
        }
      })
      .catch(err => {
        this.setState({ formLoading: false });

        if (err.inner) {
          const formErrors = err.inner.reduce((prevErrors, currentError) => {
            return {
              ...prevErrors,
              [currentError.path]: currentError.message,
            };
          }, {});
          this.setState(prevState => ({ ...prevState, formErrors }));
        } else {
          const formErrors = err.reduce((errorObject, error) => {
            if (errorObject[error.field]) {
              errorObject = {
                ...errorObject,
                [error.field]: `${errorObject[error.field]} ${error.message}`,
              };
            } else {
              errorObject = { ...errorObject, [error.field]: error.message };
            }
            return errorObject;
          }, {});

          this.setState(prevState => ({ ...prevState, formErrors }));
        }
        window.scrollTo(0, this.formRef.current.offsetTop);
      });
  };

  tokenHandler = async token => {
    const {
      t,
      i18n,
      settings,
      settings: {
        configurations: { googleTagManager },
      },
      bookings,
    } = this.props;

    let paymentInfo = this.state.paymentInfo;
    paymentInfo.token = token.id;
    paymentInfo.gatewayId = 'ML';

    try {
      const res = await this.executePayment(paymentInfo);
      let subtitle;
      if (res.bookingId !== 0)
        subtitle = t('feedbackBoxMessages.book.successSubtitle', {
          booking_id: res.bookingId,
        });

      if (is_gtag_enabled(googleTagManager)) gtag_purchase(bookings.confirmation, settings);
      history.push(
        `${tlink('__Routes.paymentSuccess', t, i18n, null, settings.configurations.langConfig)}`,
        { subtitle },
      );
    } catch (error) {
      let messageObject = {};
      let hereLink;
      const errorData = error.config.data ? JSON.parse(error.config.data) : null;

      if (error.response && error.response.data && error.response.data.message)
        messageObject['detailedError'] = t('feedbackBoxMessages.pay.errorDetails', {
          error_message: error.response.data.message,
        });

      if (this.isPayingBooking()) {
        messageObject['subtitle'] = t('feedbackBoxMessages.pay.failButConfirmed', {
          booking_id: errorData.bookingId,
        });
        hereLink = tlink(
          '__Routes.onlinePayment',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
          { bookingid: errorData.bookingId },
        );
      }

      if (this.isPayingInfraction()) {
        hereLink = tlink(
          '__Routes.onlinePayment',
          t,
          i18n,
          null,
          settings.configurations.langConfig,
          {
            infractionid: errorData.infractionId,
            customerid: errorData.customerId,
          },
        );
      }

      messageObject['buttonInfo'] = {
        text: t('tryAgain'),
        link_to: hereLink,
      };

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === RENTLY_PAY_NOTIFICACION_FAILED_CODE
      ) {
        messageObject['detailedError'] = t('feedbackBoxMessages.pay.cannotRegisterPaymentInRently');
        messageObject['buttonInfo'] = null;
      }

      history.push(
        `${tlink('__Routes.paymentFailed', t, i18n, null, settings.configurations.langConfig)}`,
        messageObject,
      );
    }
  };

  executePayment = paymentInfo => {
    if (this.isPayingBooking()) return payBooking(paymentInfo);
    if (this.isPayingInfraction()) return payInfraction(paymentInfo);
  };

  showOrderIdentifier = () => {
    const { t, bookings, settings } = this.props;
    const confirmedOrderText = settings?.paymentConfiguration?.confirmedOrderText;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      const bookingStatus = bookings.confirmation.currentStatus;

      if ([BookingStatus.reserved, BookingStatus.confirmed].includes(bookingStatus)) {
        return (
          tt(confirmedOrderText, t, true, { booking_id }) || t('ordenConfirmada', { booking_id })
        );
      }

      return t('orden', { booking_id });
    }

    if (this.isPayingInfraction())
      return `ACTA ${this.state.paymentInfo.act?.toString().padStart(7, '0') || ''}`;
  };

  isPayingBooking = () => {
    const { bookings, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.bookingid || (bookings && bookings.confirmation);
  };

  isPayingInfraction = () => {
    const { infractions, location } = this.props;
    const parseQs = queryString.parse(location.search);

    return parseQs.infractionid && parseQs.customerid && infractions && infractions.current;
  };

  getPayButtonText = () => {
    const { t } = this.props;

    if (this.isPayingBooking()) {
      const booking_id = this.state.paymentInfo.bookingId.toString().padStart(7, '0');
      return this.state.formLoading
        ? t('pagandoReserva', { booking_id })
        : t('pagarReserva', { booking_id });
    }

    if (this.isPayingInfraction()) {
      const act = this.state.paymentInfo.act?.toString().padStart(7, '0');
      return this.state.formLoading ? t('payingInfraction', { act }) : t('payInfraction', { act });
    }
  };

  setExchangeRate = exchangeRate => {
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        exchangeRate: exchangeRate,
      },
      formErrors: { ...prevState.formErrors, exchangeRate: 1 },
    }));
  };

  showTotalToPayWithInstallments = returnFinalPriceOnly => {
    const { t, bookings, infractions, location } = this.props;
    const {
      paymentInfo: { qsamount },
    } = this.state;
    const parseQs = queryString.parse(location.search);

    const isPayingInfraction = this.isPayingInfraction();
    let finalPrice = 0;
    if (qsamount && !isPayingInfraction) {
      if (parseQs?.bookingid) {
        finalPrice = Math.abs(bookings.confirmation.balance);
      }
    } else {
      finalPrice = this.state.paymentInfo.price;
    }

    if (this.isPayingBooking() && bookings.installment)
      finalPrice = bookings.installment.total_amount;

    if (isPayingInfraction && infractions.installment)
      finalPrice = infractions.installment.total_amount;

    if (returnFinalPriceOnly) {
      return finalPrice;
    }

    if (qsamount) {
      return (
        <React.Fragment>
          <NumberDisplay value={qsamount} overwrittenSymbol={this.state.currencySymbol} />
          {qsamount < finalPrice && (
            <>
              {` ${t('of')} `}
              <NumberDisplay value={finalPrice} overwrittenSymbol={this.state.currencySymbol} />
            </>
          )}
        </React.Fragment>
      );
    } else {
      return <NumberDisplay value={finalPrice} overwrittenSymbol={this.state.currencySymbol} />;
    }
  };

  handleCheckboxUseContactInfoChange = checked => {
    const customer = this.getCustomerInfo();

    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        billingInfoCheckbox: checked,
        disableBillingFields: checked,
        firstName: checked ? customer.firstName : prevState.paymentInfo.firstName,
        lastName: checked ? customer.lastName : prevState.paymentInfo.lastName,
        fiscalConditionId: checked
          ? customer.fiscalConditionId
          : prevState.paymentInfo.fiscalConditionId,
        billingDocumentType: checked
          ? customer.documentTypeId || 0
          : prevState.paymentInfo.billingDocumentType,
        documentId: checked ? customer.documentId : prevState.paymentInfo.documentId,
        address: checked ? customer.address : prevState.paymentInfo.address,
        addressNumber: checked
          ? customer.addressNumber || null
          : prevState.paymentInfo.addressNumber,
        addressDepartment: checked ? customer.addressDepartment || '' : '',
        zipCode: checked ? customer.zipCode : prevState.paymentInfo.zipCode,
        cellPhone: checked ? customer.cellPhone : prevState.paymentInfo.cellPhone,
        emailAddress: checked ? customer.emailAddress : prevState.paymentInfo.emailAddress,
      },
      formErrors: {
        firstName: '',
        lastName: '',
        fiscalConditionId: null,
        billingDocumentType: '',
        documentId: '',
        address: '',
        addressNumber: '',
        addressDepartment: '',
        zipCode: '',
        cellPhone: '',
        emailAddress: '',
      },
    }));
  };

  parseBillingInfoSection = () => {
    const { t, billingSubtitle, billingConditions } = this.props;

    return (
      <>
        <div className="billing-form pb-1 pt-3 payment-code">
          <hr />
          <div className="col-md-12 p-0">
            <p className="mb-2">
              <strong>{t('billingInformation.title')}</strong>
            </p>
            {billingSubtitle && <p className="label mb-2">{t(billingSubtitle)}</p>}
            <CheckboxInput
              onChange={this.handleCheckboxUseContactInfoChange}
              checked={this.state.paymentInfo.billingInfoCheckbox}
              text={<p className="mb-1">{t('billingInformation.equalToContactInfo')}</p>}
            />
            {this.parseBillingFields()}
          </div>
          {billingConditions && <Paragraph text={billingConditions} />}
        </div>
      </>
    );
  };

  parseBillingFields = () => {
    const { t, listDocumentTypes } = this.props;
    const {
      paymentInfo: { billingDocumentType, documentId },
      billingDocInputFocus,
      formLoading,
    } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('billingInformation.fields.firstName')}
                value={this.state.paymentInfo.firstName}
                error={this.state.formErrors.firstName}
                onChange={this.handleFirstName}
                options={{ autoComplete: 'billingInfo.firstName' }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('billingInformation.fields.lastName')}
                value={this.state.paymentInfo.lastName}
                error={this.state.formErrors.lastName}
                onChange={this.handleLastName}
                options={{ autoComplete: 'billingInfo.lastName' }}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('mail')}
                value={this.state.paymentInfo.emailAddress}
                error={this.state.formErrors.emailAddress}
                onChange={this.handleEmailAddress}
                options={{ autoComplete: 'billingInfo.emailAddress' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressName')}
                value={this.state.paymentInfo.address}
                error={this.state.formErrors.address}
                onChange={this.handleAddress}
                options={{ autoComplete: 'address-line1' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressNumber')}
                value={this.state.paymentInfo.addressNumber}
                error={this.state.formErrors.addressNumber}
                onChange={this.handleAddressNumber}
                options={{ autoComplete: 'addressNumber' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('addressDepartment')}
                value={this.state.paymentInfo.addressDepartment}
                error={this.state.formErrors.addressDepartment}
                onChange={this.handleAddressDepartment}
                options={{ autoComplete: 'addressDepartment' }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('zipCode')}
                value={this.state.paymentInfo.zipCode}
                error={this.state.formErrors.zipCode}
                onChange={this.handleZipCode}
                options={{ autoComplete: 'zipCode' }}
              />
            </div>
          </div>
          <div className={!this.state.paymentInfo.showBillingInfo ? 'col-md-4' : 'col-md-3'}>
            <div className="form-group">
              <TextInput
                inputClass="form-control"
                placeholder={t('phone')}
                value={this.state.paymentInfo.cellPhone}
                error={this.state.formErrors.cellPhone}
                onChange={this.handleCellphone}
                options={{ autoComplete: 'tel' }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <SelectInput
                inputClass="form-control"
                onChange={this.handleFiscalCondition}
                selected={this.state.paymentInfo.fiscalConditionId}
                options={transformFiscalConditionsToSelectOptions(this.state.fiscalConditions, t)}
                error={this.state.formErrors.fiscalConditionId}
                defaultOption={t('billingInformation.fields.fiscalCondition')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <SelectInput
                inputClass="form-control"
                onChange={this.handleBillingDocumentType}
                options={transformDocTypesToSelectOptions(listDocumentTypes) || []}
                selected={billingDocumentType}
                error={this.state.formErrors.billingDocumentType}
                defaultOption={t('tipodocumento')}
              />
            </div>
          </div>
          <div className="col-md-3">
            <TextInput
              inputClass="form-control"
              placeholder={t('billingInformation.fields.document')}
              error={this.state.formErrors.documentId}
              value={documentId}
              onChange={this.handleDocumentId}
              options={{
                autoComplete: 'document',
                onFocus: () => {
                  this.setState({ billingDocInputFocus: true });
                },
                onBlur: () => {
                  this.setState({ billingDocInputFocus: false });
                },
              }}
              legend={
                this.state.sampleBillingDocFormat
                  ? t('billingInformation.fields.formatExpected', {
                      format_expected: this.state.sampleBillingDocFormat,
                    })
                  : null
              }
              mask={
                !formLoading &&
                getDocumentMask(
                  documentId,
                  billingDocumentType,
                  billingDocInputFocus,
                  listDocumentTypes,
                )
              }
            />
          </div>
        </div>
      </>
    );
  };

  getCustomerInfo = () => {
    if (this.isPayingBooking()) return this.props.bookings.confirmation.customer;
    if (this.isPayingInfraction() && this.props.infractions.current) {
      return this.props.infractions.current.booking
        ? this.props.infractions.current.booking.customer
        : {};
    }
  };

  getPaymentMethods = async data => {
    const { bin } = data;
    const { mp } = this.state;
    const { results } = await mp.getPaymentMethods({ bin });
    let icon = '';

    if (!results || !results[0]) return;

    switch (results[0].id) {
      case 'visa':
      case 'debvisa':
        icon = 'fab fa-cc-visa';
        break;
      case 'amex':
      case 'american-express':
        icon = 'fab fab fa-cc-amex';
        break;
      case 'master':
      case 'debmaster':
        icon = 'fab fa-cc-mastercard';
        break;
      default:
        icon = 'far fa-credit-card';
        break;
    }
    this.setState(prevState => ({
      ...prevState,
      paymentInfo: {
        ...prevState.paymentInfo,
        paymentMethodID: results[0].id,
        bin,
      },
      cardIcon: icon,
    }));
    const issuers = await mp.getIssuers({
      paymentMethodId: results[0].id,
      bin,
    });
    this.issuersHandler(issuers);
  };

  render() {
    const {
      t,
      title,
      subtitle,
      script,
      paymentLegend,
      hideInstallmentLink,
      currentCurrency,
      defaultCurrency,
      webCurrency,
      installmentsHelperText,
    } = this.props;
    const {
      paymentInfo: { documentType, document, documents },
      docInputFocus,
      formLoading,
      formErrors,
    } = this.state;

    const publicToken = this.getPublicToken();
    if (!publicToken) return null;

    const applyExchangeRate = applyCurrencyExchangeRate(currentCurrency, webCurrency);

    const showExchangeMsg = applyExchangeRate;

    let returnFinalPriceOnly = true;

    let showInstalmentsLink = hideInstallmentLink !== undefined ? !hideInstallmentLink : true;

    let totalToPay = this.showTotalToPayWithInstallments(returnFinalPriceOnly);
    if (applyExchangeRate)
      totalToPay = getPriceWithExchangeRate(
        totalToPay,
        currentCurrency,
        defaultCurrency,
        webCurrency,
      );

    return (
      <StyledMercadoPagoSection className="col-md-9">
        <div className="row col-md-6">
          <div className="col-md-12">
            <Title
              type="h2"
              text={title}
              weight="900"
              fontSize={30}
              className={`${title != null ? '' : 'd-none'}`}
            />
          </div>
          <div className="col-md-12">
            <p className={subtitle != null ? '' : 'd-none'}>{tt(subtitle, t)}</p>
          </div>
        </div>
        <div className="payment-block">
          <div className="col-md-12 p-0 LogosContainer">
            <img
              width="60"
              height="60"
              className="mr-4"
              src="/images/ssl.jpg"
              alt={t('CertificadoSSL')}
            />
            <div className="SecureLogoContainer">
              <div className="LogoContainer">
                <VerifiedUserOutlinedIcon />
              </div>
              <div>
                <Typography variant="body1" className="CompraTitle">
                  {t('CompraSegura')}
                </Typography>
                <Typography variant="body1"> {t('SafeTransactionsSite')}</Typography>
              </div>
            </div>
          </div>
          <div className="adicionales-block pb-1 pt-3 payment-code">
            <div className="col-md-12 p-0">
              <p className="mb-1">
                <strong>{this.showOrderIdentifier()}</strong>
              </p>
              <p>
                <strong>
                  {t('totalToPay')} {this.showTotalToPayWithInstallments()}
                </strong>
              </p>
              <hr />
              <PartialPayWarning />
            </div>
            {showExchangeMsg && (
              <Paragraph className="rent-days mb-0 mt-2" color={'red'}>
                <strong>{`${t('chargedPaymentMsg')} ${
                  currentCurrency?.isoCode
                } ${totalToPay.toFixed(2)} `}</strong>
              </Paragraph>
            )}
          </div>
          <div className="row adicionales-block mb-5 pt-3">
            <div className="col-md-10 form-pago">
              <form onSubmit={this.handleSubmit} ref={this.formRef}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('numeroTarjeta')}</span>
                      <div className="mp-inputs">
                        <i className={this.state.cardIcon}></i>
                        <div id="cardNumber"></div>
                      </div>
                    </div>
                    {formErrors && formErrors.cardNumber && (
                      <div className="error">{formErrors.cardNumber}</div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('codigo')}</span>
                      <div className="mp-inputs" id="securityCode"></div>
                    </div>
                    {formErrors && formErrors.securityCode && (
                      <div className="error">{formErrors.securityCode}</div>
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <span className="label">{t('expirationDate')}</span>
                      <div className="mp-inputs" id="expirationDate"></div>
                    </div>
                    {formErrors && formErrors.expirationDate && (
                      <div className="error">{formErrors.expirationDate}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span className="label">{t('banco')}</span>
                      <SelectInput
                        inputClass="form-control"
                        onChange={this.bankSelection}
                        selected={this.state.paymentInfo.bank}
                        error={this.state.formErrors.bank}
                        options={this.state.banks}
                      />
                    </div>
                  </div>
                  {this.state.paymentInfo.showInstallments && (
                    <div className="col-md-6">
                      <div className="form-group">
                        <span className="label">{t('cuotas')}</span>
                        <SelectInput
                          inputClass="form-control"
                          onChange={this.installmentsSelection}
                          selected={this.state.paymentInfo.installment}
                          error={this.state.formErrors.installment}
                          options={this.state.installments}
                        />
                        {installmentsHelperText && t(installmentsHelperText)}
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <span className="label">{t('titular')}</span>
                      <TextInput
                        inputClass="form-control"
                        onChange={this.handleCardholderName}
                        value={this.state.paymentInfo.cardholderName}
                        options={{
                          'data-checkout': 'cardholderName',
                          id: 'cardholderName',
                        }}
                        error={this.state.formErrors.cardholderName}
                      />
                    </div>
                  </div>
                  {this.state.paymentInfo.showDoc && (
                    <>
                      <div className="col-md-2">
                        <div className="form-group">
                          <span className="label">{t('tipodocumento')}</span>
                          <SelectInput
                            inputClass="form-control"
                            onChange={this.documentSelection}
                            selected={this.state.paymentInfo.documentType}
                            options={this.state.paymentInfo.documents}
                            props={{
                              'data-checkout': 'docType',
                              id: 'docType',
                            }}
                            error={this.state.formErrors.documentType}
                          />
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="form-group">
                          <span className="label">{t('documento')}</span>
                          <TextInput
                            inputClass="form-control"
                            onChange={this.handleDocument}
                            value={this.state.paymentInfo.document}
                            error={this.state.formErrors.document}
                            options={{
                              'data-checkout': 'docNumber',
                              id: 'docNumber',
                              autoComplete: 'document',
                              onFocus: () => {
                                this.setState({
                                  ...this.state,
                                  docInputFocus: true,
                                });
                              },
                              onBlur: () => {
                                this.setState({
                                  ...this.state,
                                  docInputFocus: false,
                                });
                              },
                            }}
                            legend={
                              this.state.sampleDocFormat
                                ? t('billingInformation.fields.formatExpected', {
                                    format_expected: this.state.sampleDocFormat,
                                  })
                                : null
                            }
                            mask={
                              !formLoading &&
                              getDocumentMask(document, documentType, docInputFocus, documents)
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {paymentLegend && (
                  <>
                    <div className="row">
                      <div className="col-md-12 mb-4">
                        <Paragraph className="label" text={paymentLegend} />
                      </div>
                    </div>
                  </>
                )}
                {this.state.paymentInfo.showBillingInfo && this.parseBillingInfoSection()}
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <Btn
                      type="submit"
                      text={this.getPayButtonText()}
                      disabled={this.state.formLoading}
                      className="mt-5 mercadopago-pay_button"
                    />
                  </div>
                </div>
                <div className="">
                  <img
                    width="260"
                    height="40"
                    className="mt-4"
                    src="/images/mercado-pago.jpg"
                    alt={t('ProcesadoMercadoPago')}
                  />
                </div>
              </form>
            </div>
            <div className="col-md-2">
              {showInstalmentsLink && (
                <a
                  href="https://www.mercadopago.com.ar/cuotas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-angle-double-right"></i>
                  {t('promociones')}
                </a>
              )}
              <ScriptTagComponent
                url={script}
                loadLibrary={true}
                async={true}
                onLoaded={this.onLoaded}
              />
            </div>
          </div>
        </div>
      </StyledMercadoPagoSection>
    );
  }
}

const mapStateToProps = ({ bookings, infractions, installment, siteConfiguration, global }) => ({
  bookings: bookings,
  installment: installment,
  infractions: infractions,
  settings: siteConfiguration.settings,
  listDocumentTypes: global.listDocumentTypes,
  defaultCurrency: global.defaultCurrency,
  currentCurrency: global.currentCurrency,
  webCurrency: global.webCurrency,
});
export default connect(mapStateToProps, {
  setBookingInstallment,
  setInfractionInstallment,
  fetchDocumentTypes,
  fetchFiscalConditions,
})(withRouter(withTranslation()(MercadoPagoSection)));
