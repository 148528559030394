import React from 'react';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

import Btn from 'components/Btn';
import CheckboxInput from 'components/CheckBoxInput';
import RangeDateInput from 'components/RangeDateInput';
import SearchBoxPlace from 'components/SearchBoxPlace';
import SelectInput from 'components/SelectInput';
import TextInput from 'components/TextInput';
import withSettingsContext from 'SettingsContext';
import { StyledSearchForm } from './index.styled';
import { withTheme } from 'styled-components';
import { tt } from 'utils/translationHelper';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import * as yup from 'yup';

class LuxurySearchForm extends React.Component {
  constructor(props) {
    const { t } = props;
    super(props);
    this.state = {
      isMobile: false,
      loading: false,
      userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      formErrors: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
      popUpErrorShow: false,
    };
    this.handleResize = this.handleResize.bind(this);

    this.userInfoFormSchema = yup.object().shape({
      firstName: yup.string().required(t('inputIsRequired', { field: t('name') })),
      lastName: yup.string().required(t('inputIsRequired', { field: t('lastname') })),
      email: yup
        .string()
        .email(t('validEmail'))
        .required(t('inputIsRequired', { field: t('mail') })),
      phone: yup.string().when('hidePhoneField', {
        is: hidePhoneField => !hidePhoneField,
        then: yup.string().required(t('inputIsRequired', { field: t('phone') })),
        otherwise: yup.string(),
      }),
    });
  }

  handleResize() {
    const isMobile = window.innerWidth <= 600;
    this.setState({ isMobile });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleSubmit = async () => {
    let popUpErrorShow = this.state.popUpErrorShow;

    this.userInfoFormSchema
      .validate(this.state.userInfo, { abortEarly: false })
      .then(async valid => {
        if (valid) {
          this.props.submitSearch({
            ...this.state.userInfo,
            ...{ id: window.crypto.randomUUID() },
          });
        }
      })
      .catch(err => {
        const formErrors = err.inner
          ? err.inner.reduce((prevErrors, currentError) => {
              return {
                ...prevErrors,
                [currentError.path]: currentError.message,
              };
            }, {})
          : {};
        popUpErrorShow = true;
        this.setState(prevState => ({
          ...prevState,
          formErrors,
          popUpErrorShow,
        }));
      });
  };

  onUserFirstNameChange = firstName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, firstName },
      formErrors: { ...prevState.formErrors, firstName: '' },
      popUpErrorShow: false,
    }));
  };

  onUserLastNameChange = lastName => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, lastName },
      formErrors: { ...prevState.formErrors, lastName: '' },
      popUpErrorShow: false,
    }));
  };

  onUserMailChange = email => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, email },
      formErrors: { ...prevState.formErrors, email: '' },
      popUpErrorShow: false,
    }));
  };

  onUserPhoneChange = phone => {
    this.setState(prevState => ({
      ...prevState,
      userInfo: { ...prevState.userInfo, phone },
      formErrors: { ...prevState.formErrors, phone: '' },
      popUpErrorShow: false,
    }));
  };

  render() {
    const {
      searchText,
      className,
      categories,
      categoryId,
      onCategoryIdChange,
      featureFlags,
      promotionCodeText,
      transparent,
      checkoutText,
      showCity,
      showIATA,
      showDifferentDropPlace,
      orientation,
      focus,
      withFullScreenPortal,
      pickUp,
      dropOff,
      promotionCode,
      showInvalidPromotionMessage,
      kmFilter,
      minStayLength,
      t,
      theme,
      pickUpSelect,
      toggleDropInput,
      dropOffSelect,
      filteredDropPlaces,
      handleDateSelect,
      handleDateFocus,
      isDayBlocked,
      pickUpHourSelection,
      dropOffHourSelection,
      onPromotionCodeChange,
      handleKmFilter,
      disableSubmit,
      driverMinAge,
      driverMaxAge,
      invalidDriverAge,
      handleDriverAgeChange,
      driverAge,
      driverAgeWarning,
      isCompany,
      useDriverAgeWithoutCaution,
      driverAgeAllowedMin,
      driverAgeAllowedMax,
      useBackendSort,
      customText,
      pickUpPlaces,
      distanceUnit,
      isOutsideRange,
      loading,
      errorMessage,
    } = this.props;

    const { userInfo } = this.state;

    const kmCheckbox = (
      <div className="filtro-km">
        <CheckboxInput
          className="special-checkbox"
          text={
            t(customText) || t(distanceUnit === 'Kilometers' ? 'KmIlimitados' : 'MillasIlimitados')
          }
          checked={kmFilter.selected === true}
          onChange={e => handleKmFilter(e)}
        />
      </div>
    );

    const showPromotionCode = featureFlags.showPromotionCode
      ? featureFlags.showPromotionCode
      : false;

    const categoriesOptions =
      categories?.map(cat => ({ value: cat.id.toString(), text: cat.name })) || [];

    return (
      <StyledSearchForm
        onSubmit={e => e.preventDefault(false)}
        className={'pl-4 pr-4 ' + (className || '')}
        transparent={transparent}
      >
        {errorMessage && <p className="error">{errorMessage}</p>}
        <Row>
          <Col>
            <Row noGutters className="curstomer-info-row">
              <Col xs={6} lg={3}>
                <TextInput
                  inputClass="form-control"
                  placeholder={t('name')}
                  error={this.state.formErrors.firstName}
                  value={userInfo.firstName}
                  onChange={this.onUserFirstNameChange}
                  options={{ autoComplete: 'given-name' }}
                  wrapperId="first_name-input"
                />
              </Col>
              <Col xs={6} lg={3}>
                <TextInput
                  inputClass="form-control"
                  placeholder={t('lastname')}
                  error={this.state.formErrors.lastName}
                  value={userInfo.lastName}
                  onChange={this.onUserLastNameChange}
                  options={{ autoComplete: 'family-name' }}
                  wrapperId="last_name-input"
                />
              </Col>
              <Col xs={6} lg={2}>
                <TextInput
                  inputClass="form-control"
                  placeholder={t('mail')}
                  error={this.state.formErrors.email}
                  value={userInfo.email}
                  onChange={this.onUserMailChange}
                  options={{ autoComplete: 'email' }}
                  wrapperId="mail-input"
                />
              </Col>
              <Col xs={6} lg={2}>
                <TextInput
                  inputClass="form-control"
                  placeholder={t('phone')}
                  error={this.state.formErrors.phone}
                  value={userInfo.phone}
                  onChange={this.onUserPhoneChange}
                  options={{ autoComplete: 'tel' }}
                  wrapperId="phone-input"
                />
              </Col>
              <Col xs={12} lg={2}>
                <SelectInput
                  className="form-control"
                  wrapperClass="category-select"
                  selected={categoryId}
                  onChange={onCategoryIdChange}
                  options={[{ value: 'all', text: `${t('allCategories')}` }, ...categoriesOptions]}
                />
              </Col>
            </Row>
            <Row noGutters>
              <Col lg={showPromotionCode ? 3 : 4} className="pickup-place-picker">
                <label htmlFor="selectFromPlace" className="label">
                  {t('deliveryPlace')}
                </label>
                <SearchBoxPlace
                  name="selectFromPlace"
                  data={pickUpPlaces}
                  placeChangeHandler={pickUpSelect}
                  placeSelected={pickUp.place || ''}
                  placeholder={t('selectPickUpPlace')}
                  showCity={showCity || false}
                  showIATA={showIATA || false}
                  useBackendSort={useBackendSort}
                />

                <div className="return-place-picker">
                  {pickUp && pickUp.place && pickUp.place.value && (
                    <div className="diff-drop-place" onClick={toggleDropInput}>
                      {t('differentreturnplace')}
                    </div>
                  )}
                  {showDifferentDropPlace && (
                    <>
                      <label htmlFor="selectToPlace" className="label">
                        {t('LugarDeDevolucion')}
                      </label>
                      <div className="mb-4">
                        <SearchBoxPlace
                          name="selectToPlace"
                          className="search-box-place"
                          data={filteredDropPlaces()}
                          placeChangeHandler={dropOffSelect}
                          placeSelected={dropOff.place}
                          placeholder={t('SeleccionarLugarDeDevolucion')}
                          showCity={showCity || false}
                          showIATA={showIATA || false}
                          useBackendSort={useBackendSort}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Col>
              <Col xs={12} lg={4} className="range-date-picker">
                <RangeDateInput
                  label={t('deliveryReturnDate')}
                  onDatesChange={handleDateSelect}
                  onFocusChange={handleDateFocus}
                  startDateId="drop-up-date-start"
                  startDate={pickUp.moment}
                  endDate={dropOff.moment}
                  icon="far fa-calendar"
                  iconColor={theme.colors.buttonPrimaryBgHover}
                  isDayBlocked={isDayBlocked}
                  datePickerOptions={{
                    minimumNights: minStayLength,
                    focusedInput: focus,
                    orientation: this.state.isMobile ? 'vertical' : orientation,
                    withFullScreenPortal: this.state.isMobile ? true : withFullScreenPortal,
                    customArrowIcon: <span className="fas fa-arrow-right p-0 mr-2" />,
                    startDatePlaceholderText: t('deliveryDate'),
                    endDatePlaceholderText: t('returnDate'),
                    hideKeyboardShortcutsPanel: true,
                  }}
                  isOutsideRange={isOutsideRange}
                />
              </Col>
              <Col lg={showPromotionCode ? 5 : 4}>
                <Row noGutters>
                  <Col lg={showPromotionCode & !isCompany ? 5 : 12} className="no-right-padding">
                    <Row noGutters>
                      <Col xs={6} className="hour-select hour-select-from">
                        <SelectInput
                          onChange={pickUpHourSelection}
                          label={t('delivery')}
                          icon="far fa-clock"
                          selected={pickUp.hour}
                          iconColor={theme.colors.buttonPrimaryBgHover}
                          options={pickUp.hours}
                          name="pickupTime"
                        />
                      </Col>
                      <Col
                        xs={6}
                        className={`hour-select hour-select-to ${
                          !showPromotionCode ? 'no-right-padding' : ''
                        }`}
                      >
                        <SelectInput
                          name="returnTime"
                          onChange={dropOffHourSelection}
                          label={t('return')}
                          icon="far fa-clock"
                          selected={dropOff.hour}
                          iconColor={theme.colors.buttonPrimaryBgHover}
                          options={dropOff.hours}
                        />
                        {dropOff.checkoutTime && (
                          <div
                            style={{
                              whiteSpace: 'break-spaces',
                              fontSize: '9px',
                              color: '#ff0000',
                            }}
                          >
                            {t(checkoutText) || t('checkoutMsg')} ({dropOff.checkoutTime})
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  {showPromotionCode & !isCompany ? (
                    <div className="col-lg-7 no-right-padding">
                      <TextInput
                        className="promotion-code-input"
                        label={t('CodigoDePromocion')}
                        icon="fas fa-star"
                        iconColor={theme.colors.buttonPrimaryBgHover}
                        placeholder={t(promotionCodeText) || t('EscribiTuCodigo')}
                        onChange={onPromotionCodeChange}
                        disabled={isCompany ? true : false}
                        value={promotionCode || ''}
                        error={showInvalidPromotionMessage ? t('CodigoDePromocionInvalido') : ''}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-0"></div>
                  )}
                </Row>
                <Row noGutters>
                  <Col lg={showPromotionCode ? 5 : 6}>
                    {featureFlags && featureFlags.kilometerFilters && kmCheckbox}
                  </Col>
                  <Col lg={showPromotionCode ? 7 : 6}>
                    {featureFlags && featureFlags.showDriverAge && (
                      <div className="driver-age">
                        {!useDriverAgeWithoutCaution ? (
                          <div className="special-label">
                            <label htmlFor="driverAgeWithoutCautionLabel" className="label">
                              {t('driverAgeBetween', {
                                min: driverAgeAllowedMin,
                                max: driverAgeAllowedMax,
                              })}
                            </label>
                            <Tooltip
                              placement=""
                              title={t('driverAgeAllowedBetween', {
                                min: driverAgeAllowedMin,
                                max: driverAgeAllowedMax,
                              })}
                              className=""
                            >
                              <i className="fas fa-info-circle"></i>
                            </Tooltip>
                          </div>
                        ) : (
                          <>
                            <div className="special-checkbox">
                              <CheckboxInput
                                text={t('driverAgeBetween', {
                                  min: driverMinAge,
                                  max: driverMaxAge,
                                })}
                                checked={driverAge.betweenMinAndMax}
                                onChange={e => handleDriverAgeChange(e)}
                              />
                              <Tooltip
                                placement="top"
                                title={
                                  tt(driverAgeWarning, t) ||
                                  t('driverAgeWarning', {
                                    min: driverMinAge,
                                    max: driverMaxAge,
                                  })
                                }
                              >
                                <i className="fas fa-info-circle"></i>
                              </Tooltip>
                            </div>
                            {!driverAge.betweenMinAndMax && (
                              <div className="driver-age-input">
                                <TextInput
                                  label={t('driverAge')}
                                  onChange={value => handleDriverAgeChange(false, value)}
                                  value={driverAge.age || ''}
                                  type="number"
                                  error={
                                    driverAge.age && invalidDriverAge ? t('driverAgeError') : ''
                                  }
                                />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg={2} className="submit-btn-row">
            <Btn
              className="submit-btn search-form_button"
              text={loading ? t('searching') : tt(searchText) || t('search')}
              onClick={() => this.handleSubmit()}
              disabled={disableSubmit() || this.state.loading}
            />
          </Col>
        </Row>
      </StyledSearchForm>
    );
  }
}

export default withSettingsContext(withTheme(withTranslation()(LuxurySearchForm)));
